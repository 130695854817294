<template>
  <div>
    <!-- <b-card
      style="background-color:black;"
    > -->
      <b-img 
        :src="require('@/assets/images/placeholders/worlds.png')" 
        fluid
        center
        style="max-height: 80vh;" 
        alt="Responsive image" />    
    <!-- </b-card> -->
  </div>
</template>

<script>
import {
  BCard, BCardText, BImg, BLink,
} from 'bootstrap-vue'
export default {
  components: {
    BImg,
    BCard

  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
}
</script>

<style>
.box {
  display: flex;
  flex-wrap: wrap;
}
.box div {
  flex: 1 1 auto;
}
</style>